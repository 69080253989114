import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { EventsService } from '@core/events';
import { DOCUMENT, WINDOW } from '@core/injection-tokens';
import { RouterService } from '@core/router';
import {
  HeaderInnerType,
  SearchNavSection,
  ShoppingCartSection,
  SubNavSections,
} from '@shared/header/types/header.inner.type';
import { waitUntil } from '@shared/rxjs-operators/wait-until.operator';
import { generateUniqueId } from '@shared/ui/utils/global-utils/generate-unique-id';
import { AuthFacade } from '@store/auth/auth.facade';
import { CartFacade } from '@store/cart/cart.facade';
import { GlobalFacade } from '@store/global/global.facade';
import { RouterFacade } from '@store/router/router.facade';
import { JbFlyoutComponent } from 'jb-component-library';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { TrueBlueSection } from '../types';

/**
 * @description Component on the header that contains all the site navigation to be used on a desktop viewport
 * @WhereIsItUsed Header desktop component
 */
@Component({
  selector: 'jb-subnav-desktop',
  templateUrl: './subnav-desktop.component.html',
  styleUrls: ['./subnav-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavDesktopComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  @ViewChildren('megaNavLinks', { read: ElementRef }) megaNavLinks;
  @ViewChild('flyoutSignedOut') tbSignedOutState: JbFlyoutComponent;
  @ViewChild('flyoutSignedOut', { read: ElementRef }) flyout: ElementRef;
  @Input() set headerData(content: HeaderInnerType) {
    this.subNavSections = content.subNavSections;
    this.shoppingCart = content.shoppingCart;
    this.menuLength = Object.keys(this.subNavSections).length;
    this.tbLoggedInIndex = this.menuLength;
    this.open = new Array(this.menuLength + 1).fill(false);
    this.trueBlueSection = content.trueBlue;
    this.searchNavSection = content.search;
  }

  activeSubNavLink: any;
  arrayLastMenu = [];
  arrayOfLinks = [];
  arrayOfSubNavLinks = [];
  filteredSubNavSections: {};
  focusInside = false; // this will be used to check if focus need to be 'inside' the navbar
  hideAtFirst = true;
  initialHide = true;
  isFetchingProfile: Observable<boolean> = this.authFacade.profileIsPending;
  isLoggedIn = false;
  isTBSignedOutOpen: BehaviorSubject<boolean>;
  menuLength: number;
  open: boolean[]; // only one tab should be open at a time
  searchNavSection: SearchNavSection;
  shoppingCart: ShoppingCartSection;
  somethingIsOpen = false;
  subNavSections: SubNavSections;
  subnavWrapperId = generateUniqueId('subnav-wrapper');
  tbLoggedInIndex: number;
  trueBlueSection: TrueBlueSection;
  isDestroyed$ = new Subject<void>();

  @Input() hideTb;

  constructor(
    private elementRef: ElementRef,
    public authFacade: AuthFacade,
    public cartFacade: CartFacade,
    @Inject(WINDOW) public window,
    private routerFacade: RouterFacade,
    private eventsService: EventsService,
    private routerService: RouterService,
    private globalFacade: GlobalFacade,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document,
  ) {
    this.authFacade.isProfileLoaded
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(loggedIn => {
        this.isLoggedIn = loggedIn;
        this.hideAtFirst = false;
      });
  }

  ngOnInit() {
    this.isTBSignedOutOpen = new BehaviorSubject(false);
    // close the menu if there is a new page routed to (triggers on ROUTER_NAVIGATION)
    this.routerFacade.currentRoute
      .pipe(
        takeUntil(this.isDestroyed$),
        filter(() => Boolean(this.open) && this.open.length > 0),
      )
      .subscribe(() => {
        this.changeFocusAfterRouteChange();
      });

    this.routerService.isNavigatingToTemplate
      .pipe(
        takeUntil(this.isDestroyed$),
        filter(res => {
          return !res.navigating && res.id !== 1 && !res.url?.includes('#');
        }),
        waitUntil(
          this.globalFacade.templateRendered.pipe(
            debounceTime(0),
            filter(isRendered => isRendered),
          ),
        ),
      )
      .subscribe(() => {
        this.changeFocusAfterRouteChange();
      });
  }

  ngAfterViewInit() {
    // TODO: remove this check once links are available under dropdown, should work fine
    this.megaNavLinks.forEach(el => {
      // get all the mega links instead of just main links
      this.arrayOfLinks.push(
        el.nativeElement.querySelectorAll(
          'a.main-nav-link, .profile-wrapper a:not(.royal-blue)',
        ),
      );

      // get all links of menu
      // this will be used to set focus on links inside of menus
      this.arrayOfSubNavLinks.push(
        el.nativeElement.querySelectorAll(
          '.subnav-container, .profile-wrapper .dropdown ul',
        ),
      );
    });

    // get the last menu links
    // when sign in, the menu wll be profile
    this.arrayLastMenu = this.arrayOfSubNavLinks[0][
      this.arrayOfSubNavLinks[0].length - 1
    ]?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex], a.royal-blue',
    );
    // convert nodelist to array
    this.arrayLastMenu = [].slice.call(this.arrayLastMenu || []);
    this.arrayOfLinks = [].slice.call((this.arrayOfLinks || [])[0]);

    if (this.tbSignedOutState) {
      this.tbSignedOutState.afterOpened
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(() => {
          this.isTBSignedOutOpen.next(true);
          // Make flyout scrollable on landscape mode
          const navBox = this.elementRef.nativeElement.getBoundingClientRect();
          this.document.documentElement.style.setProperty(
            '--header-desktop-height',
            `${navBox.top + navBox.height}px`,
          );
        });
      this.tbSignedOutState.afterClosed
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(() => {
          this.isTBSignedOutOpen.next(false);
        });
    }
  }

  ngAfterViewChecked() {
    const signedOutTbHeight =
      this.document.getElementsByTagName('dot-tb-signed-out')[0]?.offsetHeight;
    signedOutTbHeight
      ? this.document.documentElement.style.setProperty(
          '--signed-out-tb-height',
          `${signedOutTbHeight}px`,
        )
      : this.document.documentElement.style.setProperty(
          '--signed-out-tb-height',
          `0px`,
        );
  }
  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  signIn() {
    this.eventsService.dispatchCustomEvent(
      EventsService.CUSTOM_EVENTS.JB_HEADER_LOGIN_CLICKED_OUTPUT_EVENT,
    );
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event) {
    // close all tabs if user clicks outside subnav
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.open.fill(false);
      this.somethingIsOpen = false;
    }
  }
  shouldCloseMenu(event) {
    const i = this.arrayOfLinks.indexOf(event.target);
    // when user is logged in,
    // all menu should close after leaving profile
    // instead of landing on profile
    const menuLength = this.isLoggedIn
      ? this.arrayOfLinks.length
      : Object.keys(this.subNavSections).length;
    const lastMenuItem = this.arrayLastMenu[this.arrayLastMenu.length - 1];
    return (
      (event.shiftKey && i === 0 && !this.focusInside) ||
      (!event.shiftKey &&
        ((event.target !== this.activeSubNavLink &&
          i < menuLength &&
          i + 1 > menuLength - 1) ||
          lastMenuItem === event.target))
    );
  }
  handleKeydown(event) {
    // this is keydown, so we are listening to the 'previous' elem
    const i = this.arrayOfLinks.indexOf(event.target);
    let elemFocus = null; // hold html element for focus

    if (event.keyCode === 9) {
      if (this.shouldCloseMenu(event)) {
        this.activeSubNavLink = null;
        this.handleEsc();
      }

      if (event.shiftKey) {
        // if previous navbar menu 'is' open
        if (this.open[i] && i !== -1) {
          // this will tell if next focus should go inside the menu
          if (this.focusInside) {
            // get all a tags links of the specific menu
            // to focus on last link inside of menu
            const focusSubNavLinks = this.arrayOfSubNavLinks[0][
              i
            ].children[0].querySelectorAll(
              'button, [href], input, select, textarea, [tabindex]',
            );
            elemFocus = focusSubNavLinks[focusSubNavLinks.length - 1];
            this.focusInside = false;
          } else {
            elemFocus = this.arrayOfLinks[i - 1];
          }
        }

        // check if the 'NEXT' focus has a open subnav
        // if it does, our next focus element
        // should be 'INSIDE' of menu
        if (this.open[i - 1] && i !== -1) {
          this.focusInside = true;
        }
      }
      // if previous navbar is 'not' open
      // grab proper element for normal focus

      if (!elemFocus && !this.open[i] && i !== -1) {
        elemFocus = !event.shiftKey
          ? this.arrayOfLinks[i + 1]
          : this.arrayOfLinks[i - 1];
      }
      // if it's not empty
      // this will set focus to element
      // in case if element is not visible, it will not focus
      if (!!elemFocus && elemFocus.offsetParent) {
        event.preventDefault();
        elemFocus.focus();
      }
    }
  }
  handleEsc() {
    // close all tabs if user hits esc key
    this.open.fill(false);
    this.somethingIsOpen = false;
    if (!!this.activeSubNavLink) {
      // set focus back to the element
      // where toggleOpen was triggered
      this.activeSubNavLink.focus();
      this.activeSubNavLink = null;
    }
  }

  toggleOpen(_, index: number) {
    this.activeSubNavLink = this.arrayOfLinks[index];

    // Hack for server side render
    this.initialHide = false;

    this.somethingIsOpen = this.open.includes(true);

    // It's just about to get toggled off
    if (this.open[index]) {
      this.somethingIsOpen = false;
    }

    this.open.forEach((openVal: boolean, i: number) => {
      // toggle specified index and close all other subnav sections
      i === index ? (this.open[i] = !openVal) : (this.open[i] = false);
    });
  }

  changeFocusAfterRouteChange() {
    this.open.fill(false);
    this.somethingIsOpen = false;
    this.routerService.resetFocus();
    this.cdr.detectChanges();
    this.tbSignedOutState.close();
  }
}
