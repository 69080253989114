import { anyPass, compose, isEmpty, isNil, pick, propOr } from 'ramda';

import {
  AuthProfile,
  AuthState,
  LeanCreditProfile,
  LeanProfile,
} from './types';

export const mapLeanProfileToExistingProfile = (
  profile: LeanProfile | LeanCreditProfile,
  isCpEnabled: boolean,
): AuthProfile => {
  if (profile?.['cbn'] && isCpEnabled) {
    return creditProfileFactory(profile as LeanCreditProfile);
  }
  return loyaltyProfileFactory(profile as LeanProfile);
};

const creditProfileFactory = (profile: LeanCreditProfile): AuthProfile => {
  return {
    fname: profile.firstName,
    lname: profile.lastName,
    birthDate: profile.birthDate,
    age: getProfileAge(profile.birthDate),
    name: `${profile.firstName} ${profile.lastName}`,
    authProfileSource: 'leancreditprofile',
  };
};

const loyaltyProfileFactory = (profile: LeanProfile): AuthProfile => {
  return {
    fname: profile.firstName,
    lname: profile.lastName,
    birthDate: profile.birthDate,
    age: getProfileAge(profile.birthDate),
    isMosaic: profile?.mosaicStatus?.toString(),
    name: `${profile.firstName} ${profile.lastName}`,
    membershipid: profile.tbNumber,
    points: profile.pointsBalance.toString(),
    cardStatus: profile.cardType,
    profileImage: profile.profileImage,
    profileImageType: profile.profileImageType,
    prefAirport: profile.prefAirport,
    authProfileSource: 'leanprofile',
    mosaicTier: profile.mosaicTier,
    numberOfTiles: profile.numberOfTiles,
  };
};

export const tryAssembleAuthProfileFromLeanProfile = (
  input: AuthState,
): AuthProfile => {
  const { leanProfileFragment } = compose(
    pick(['leanProfileFragment']),
    propOr({}, 'authProfileForOktaAndLeanProfileService'),
  )(input) as any;

  if (!leanProfileFragment) {
    return null;
  }

  return {
    ...leanProfileFragment,
  };
};

export const constructPictureBlob = (input: AuthProfile) => {
  if (input.authProfileSource !== 'leanprofile') {
    return null;
  }

  const { profileImageType, profileImage } = input;

  const isInvalid = anyPass([isNil, isEmpty]);
  const bothFieldsAreAvailable = !(
    isInvalid(profileImageType) || isInvalid(profileImage)
  );

  if (!bothFieldsAreAvailable) {
    return null;
  }

  return `data:${profileImageType};base64,${profileImage}`;
};

export function getProfileAge(birthDate: string): number {
  try {
    const plainDate = new Date(birthDate);
    if (plainDate instanceof Date && !isNaN(plainDate.getTime())) {
      const utcDateString = plainDate.toUTCString();
      const formattedBirthDate = new Date(utcDateString);
      const today = new Date();
      return today.getUTCFullYear() - formattedBirthDate.getUTCFullYear();
    } else {
      return undefined;
    }
  } catch (_) {
    return undefined;
  }
}
