import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  B6_TYPE,
  CONFIG_AUTH_IS_IDX_LOCALSTORAGE,
  CONFIG_X_AUTH_TOKEN,
} from '@core/app-config/types/mocks/app-config-constants';
import {
  ApiError,
  AppConfigService,
  clearPersonalizationCookies,
  CookieStorageService,
  HttpService,
  LocalStorageService,
  SessionStorageService,
  WINDOW,
} from '@core/index';
import { MOCK_TRUEBLUE_RESPONSE } from '@shared/trueblue/trueblue.response.mock';
import { createErrorAction, networkError } from '@store/shared/action.utils';
import { StoreService } from '@store/shared/store.service';
import { NetworkError } from '@store/shared/types/network-error.type';
import { from, Observable, of as observableOf, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { OktaService } from '../api/okta.service';
import { AuthActions } from '../auth.actions';
import { AuthProfile, LeanCreditProfile, LeanProfile } from '../types';

interface LoginCredentialsOkta {
  password: string;
  userName: string;
}

@Injectable({ providedIn: 'root' })
export class AuthApi {
  constructor(
    private http: HttpService,
    private appConfig: AppConfigService,
    private cookieService: CookieStorageService,
    private storeService: StoreService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private oktaService: OktaService,
    @Inject(WINDOW) private window,
  ) {}

  loginOkta(email: string, password: string): Observable<any | NetworkError> {
    const url = this.appConfig.loginOrchestratorUrl;
    const body: LoginCredentialsOkta = { userName: email, password };
    const headers: HttpHeaders = new HttpHeaders({
      apiKey: this.appConfig.loginOrchestratorApiKey,
    });
    return this.http
      .post<{ sessionToken: string }>(url, body, { headers })
      .pipe(
        catchError(res => {
          const serverErrMsg = `Oops. Our servers are being wonky right now.
          We’re sorry for the bump on the road to all-out JetBlue bliss.
          Please try again later.`;
          let errorValue = {} as any;
          if (!!res.status) {
            res.message = serverErrMsg;
            errorValue = res;
          } else {
            if (!!res.error) {
              const apiError: ApiError = res.error;
              apiError.message = serverErrMsg;
              // overwrite invalid credentials error message
              if (
                !!apiError.code &&
                apiError.code === 'JB_INVALID_CREDENTIALS'
              ) {
                apiError.message =
                  MOCK_TRUEBLUE_RESPONSE.login.formErrorMessage;
              }
              errorValue = apiError;
            }
          }
          this.storeService.dispatchAction(
            createErrorAction(AuthActions.OKTA_USER_LOGGED_IN, errorValue),
          );
          return observableOf(networkError());
        }),
      );
  }

  /**
   * These cookies are currently being deleted by node's /signout route
   * but just in case that is not called, try to remove them here
   */
  logout() {
    // remove true blue cookie
    this.cookieService.removeCookie('jbTrueBlueCookie');
    // remove cardStatus cookie
    this.cookieService.removeCookie('cardStatus');
    // remove points cookie
    this.cookieService.removeCookie('points');
    // remove lastVisti cookie
    this.cookieService.removeCookie('lastVisit');
    // remove recently (Dec 6 2019) added personalization cookies
    clearPersonalizationCookies(key => this.cookieService.removeCookie(key));
  }

  sessionEndJbdomains(): Observable<any> {
    // ending session for pre configured domains
    const nonJbDomainSessions = this.appConfig.okta.oktaLogout.jbSessions;
    const jbSessions = Object.keys(nonJbDomainSessions);
    if (jbSessions.length) {
      const sites = jbSessions.reduce((allSites, key) => {
        return [...allSites, nonJbDomainSessions[key]];
      }, []);
      return from(sites).pipe(
        mergeMap(nonJbdomain => {
          const isSession = this.cookieService.getCookie(nonJbdomain.cookie);
          return isSession
            ? this.http
                .get<string>(nonJbdomain.url)
                .pipe(catchError(() => observableOf(networkError())))
            : of(null);
        }),
      );
    } else {
      return of(null);
    }
  }

  deleteAuthCookies(): void {
    // deleting jb cookies
    const deleteCookies = this.appConfig.deleteCookies;
    for (const cookie of deleteCookies) {
      this.cookieService.removeCookie(cookie);
    }
  }

  deleteAuthLocalStorageItems(): void {
    // deleting jb localStorage items
    const deleteItems = this.appConfig.deleteLocalStorage;
    for (const key of deleteItems) {
      this.localStorageService.removeItem(key);
    }
    // deleting localStorage that is flaging Idx
    this.localStorageService.removeItem(CONFIG_AUTH_IS_IDX_LOCALSTORAGE);
    this.localStorageService.removeItem(CONFIG_X_AUTH_TOKEN);
  }

  deleteAuthSessionStorageItems(): void {
    // deleting OKTA transaction from session storage - DOT-11254
    this.sessionStorageService.removeItem('okta-transaction-storage');
  }

  deleteOrchestrator(data: {
    uid: string;
    token: string;
  }): Observable<any | NetworkError> {
    const url = `${this.appConfig.okta.oktaLogout.oktaSessionUrl}/${data.uid}/sessions?oauthTokens=${this.appConfig.oauthTokens}`;
    const headers = new HttpHeaders({
      'Content-Type': this.appConfig.okta.oktaLogout.contentType,
      Authorization: `Bearer ${data.token}`,
      apiKey: this.appConfig.okta.oktaLogout.apikey,
    });

    return this.http.delete<any>(url, { headers }).pipe(
      catchError(() =>
        this.oktaService.closeSession().catch(error => {
          this.storeService.dispatchAction(
            createErrorAction(AuthActions.OKTA_USER_LOGGED_OUT_FAILURE, error),
          );
          return observableOf(networkError());
        }),
      ),
    );
  }

  setCookiesAfterLoginSuccess(profile: AuthProfile) {
    if (profile.authProfileSource === 'leanprofile') {
      const { fname, isMosaic, membershipid, points, prefAirport, cardStatus } =
        profile;

      cardStatus && cardStatus.length
        ? this.cookieService.setProfileInfoCookie(
            'cardStatus',
            this.cardStatusPrioritization(cardStatus),
          )
        : this.cookieService.setProfileInfoCookie(
            'cardStatus',
            'defaultNoCard',
          );
      if (points) {
        this.cookieService.setProfileInfoCookie('points', points);
      }
      this.cookieService.setUserMemberStatusCookie(isMosaic);
      this.cookieService.setTrueBlueCookie(
        points,
        fname,
        isMosaic,
        prefAirport,
        membershipid,
      );
      if (prefAirport) {
        // Sometimes profiles don't have preferred airports
        this.cookieService.setBaseAirportCookie(prefAirport[0]);
      }
    }
  }

  requestLeanProfile(
    accessToken: string,
  ): Observable<LeanProfile | LeanCreditProfile | NetworkError> {
    // Detect if B6_TYPE cookie is of kind of Credit Profile
    const isCreditAccount =
      this.cookieService.getCookie(B6_TYPE) ===
      this.appConfig?.okta?.oktaAuthType?.creditProfile;

    // Once the LeanProfile for Credit Service is completed, we will implement logic here to dynamically switch the URL.
    // Loyalty team will use our regular LeanProfile API.
    // Credit Profile users are going to use a new Service - TBD.
    // For now, as part of the POC, we will return mock data if the below condition is true.
    if (this.appConfig.isCpEnabled && isCreditAccount) {
      return of({
        title: 'MR',
        firstName: 'John',
        lastName: 'Doe',
        birthDate: '1980-01-01',
        tbnkBalance: 200,
        cbn: '111111111',
      });
    }

    const url = this.appConfig.leanProfileUrl;
    const body: any = { displayImage: true, jwt: accessToken };
    const headers: HttpHeaders = new HttpHeaders({
      apiKey: this.appConfig.leanProfileApiKey,
    });

    return this.http.post<any>(url, body, { headers }).pipe(
      catchError(response => {
        // Dispatches the error state to reset the profile fetched
        this.storeService.dispatchAction(
          createErrorAction(AuthActions.LEAN_PROFILE_FAILED, response),
        );
        return observableOf(networkError());
      }),
    );
  }
  cardStatusPrioritization(cardStatusArray: string[]): string {
    const prioritizedArray = ['BC05', 'BC01', 'BC04', 'BC02', 'BC00'];
    const cobrandDomainCodes = [
      'BC05',
      'BC01',
      'BC04',
      'BC03',
      'BC02',
      'BC00',
      'BPR2',
      'BPR3',
      'BPR1',
      'BPR4',
    ];
    cardStatusArray.sort(
      (a, b) => cobrandDomainCodes.indexOf(a) - cobrandDomainCodes.indexOf(b),
    );
    const cardStatus = prioritizedArray.find(i => i === cardStatusArray[0]);
    return cardStatus ? cardStatus : 'defaultCard';
  }
}
