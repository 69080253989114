@if(!!profile){
<div
  *ngIf="isMobile"
  class="pa0 logged-in-button flex items-center white no-underline relative"
>
  <ng-template [ngTemplateOutlet]="mobileView"></ng-template>
</div>
<div
  *ngIf="!isMobile"
  class="new-header profile-wrapper relative-ns h-100 w-100 fr-ns flex items-center-ns dib-ns"
>
  <ng-template [ngTemplateOutlet]="desktopView"></ng-template>
</div>

}

<!-- Mobile View -->
<ng-template #mobileView>
  <button
    (click)="changeModalStateOnClick()"
    class="pa0 logged-in-button flex items-center white no-underline profile-container relative"
  >
    <span class="flex justify-end items-center mr0-ns nt2">
      <jb-icon
        *ngIf="isMosaic"
        name="avatarMosaicStatus"
        class="nr2 z-2 mosaic-logo mt3"
        width="22"
        label="mosaic"
        data-qaid="mosaicStatus"
      >
      </jb-icon>
      <span class="flex justify-center z-1 white f5 mr0-m br-100 mt2 mt0-ns">
        <jb-avatar
          *ngIf="!(profilePicture$ | async) && !profile.profileImage"
          [initials]="getNameInitials()"
          size="small"
        >
        </jb-avatar>
        <jb-avatar
          size="small"
          *ngIf="(profilePicture$ | async) || profile.profileImage as pic"
          [src]="pic"
        >
        </jb-avatar>
      </span>
      <hr
        [ngClass]="isModalOpened ? 'w-100' : 'w-0'"
        class="db absolute bottom-0 hr-underline"
      />
    </span>
  </button>
  <div *ngIf="isModalOpened" class="logged-in-modal bg-white">
    <dot-header-signed-in-dialog
      [content]="content"
      class="db"
    ></dot-header-signed-in-dialog>
  </div>
</ng-template>

<!-- Desktop View -->
<ng-template #desktopView>
  <span
    class="h-100 flex items-center-ns"
    jbAnchor
    #anchorSignedIn="jbAnchor"
    [flyout]="flyoutSignedIn"
  >
    <a
      (click)="anchorSignedIn.toggle()"
      href="javascript:void(0);"
      class="w-100 flex items-center white no-underline profile-container-hover"
      [ngClass]="isModalOpened ? 'profile-container' : ''"
    >
      <div class="flex justify-end items-center mr0-ns mr1">
        <!-- Mosaic Icon that will appear in the User Icon -->
        <jb-icon
          *ngIf="isMosaic"
          name="avatarMosaicStatus"
          class="nr2 z-2 mosaic-logo mt3"
          width="22"
          label="mosaic"
          data-qaid="mosaicStatus"
        >
        </jb-icon>

        <!-- User Profile Image or User Initials -->
        <div
          class="flex justify-center z-1 white overflow-hidden f5 mr0-m bg-bright-blue br-100 mt2 mt0-ns"
        >
          <jb-avatar
            *ngIf="!(profilePicture$ | async) && !profile.photoUrl"
            [initials]="getNameInitials()"
            size="small"
          >
          </jb-avatar>
          <jb-avatar
            *ngIf="(profilePicture$ | async) || profile.photoUrl as pic"
            [src]="pic"
            size="small"
          >
          </jb-avatar>
        </div>
      </div>

      @if(points){
      <p class="pl2 mb0 klarheit f6 fw6 lh-copy dn-m db-l">
        {{ points }} {{ content?.topSection?.children?.pts }}
      </p>
      }
    </a>

    <!-- A flyout container that will appear when clicking in the Profile Image -->
    <jb-flyout
      #flyoutSignedIn
      [placement]="'bottom-end'"
      [minWidth]="375"
      class="jb-shadow-1"
      [minIsAnchorWidth]="false"
    >
      <div class="scrollable">
        <dot-header-signed-in-dialog
          class="db"
          [content]="content"
          (closeEvent)="flyoutSignedIn.toggle()"
        ></dot-header-signed-in-dialog>
      </div>
    </jb-flyout>
    <hr
      [ngClass]="isModalOpened ? 'w-100 active' : 'w-0'"
      class="absolute bottom-0 hr-underline"
    />
  </span>
</ng-template>
