import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { AppConfigService } from '@core/index';
import { TrueBlueLoggedInSection } from '@shared/trueblue/tb-logged-in/types/new-tb-logged-in.response.type';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import { AuthFacade } from '@store/auth/auth.facade';
import { AuthProfile } from '@store/auth/types';
import { PreferenceCenterFacade } from '@store/preference-center/preference-center.facade';
import { ProfileFacade } from '@store/profile/profile.facade';
import { combineLatest, filter, Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'dot-header-signed-in-dialog',
  templateUrl: './header-signed-in-dialog.component.html',
  styleUrls: ['./header-signed-in-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSignedInDialogComponent implements OnDestroy {
  @Input() set content(content: TrueBlueLoggedInSection) {
    this.loggedInSection = content;
    this.bgColor =
      content?.topSection?.children?.backgroundColor?.[0]?.bgColorTrueblue;
    this.theme = content?.topSection?.children?.trueBlueTheme;

    if (this.profile && this.isMosaic) {
      this.theme = content?.topSection?.children?.mosaicTheme;
      this.bgColor =
        content?.topSection?.children?.backgroundColor?.[0]?.bgColorMosaic;
    }
    const buttomStyle = content?.topSection?.children?.link?.[0]?.buttonStyle;
    switch (buttomStyle) {
      case 'secondary':
        this.buttonType = 'ctaSecondary';
        break;
      case 'primary':
        this.buttonType = 'ctaPrimary';
        break;
      default:
        this.buttonType = buttomStyle;
    }
  }
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  public loggedInSection: TrueBlueLoggedInSection;
  public travelBankBalance$: Observable<number>;
  public profile: AuthProfile;
  public firstName: string;
  public points: string;
  public tbNumber: string;
  public mosaicTier: string;
  public isMosaic: boolean;
  public bgColor: string;
  public buttonType = 'standard';
  public theme: string;
  private _isDestroyed$ = new Subject<void>();

  constructor(
    private _authFacade: AuthFacade,
    private _profileFacade: ProfileFacade,
    private _preferenceCenterFacade: PreferenceCenterFacade,
    public appConfigService: AppConfigService,
  ) {
    this.travelBankBalance$ = this._preferenceCenterFacade.travelBankBalance;

    combineLatest([
      this._authFacade.authProfile.pipe(filter<AuthProfile>(Boolean)),
      this._profileFacade.profileIsLoading,
      this._profileFacade.memberProfile,
    ])
      .pipe(takeUntil(this._isDestroyed$))
      .subscribe(([authProfile, tbProfileIsLoading, tbProfile]) => {
        this.profile = authProfile;

        this.isMosaic = booleanStringCheck(
          this.profile?.isMosaic?.toLowerCase(),
        );
        this.mosaicTier = this.profile.mosaicTier;

        this.firstName =
          this.profile.fname.charAt(0).toUpperCase() +
          this.profile.fname.slice(1);

        // Only for Loyalty (TrueBlue) Users
        if (authProfile.authProfileSource === 'leanprofile') {
          this.tbNumber = this.profile.membershipid;
          if (!!tbProfile) {
            const curLoyalty = (tbProfile?.loyalty || []).find(
              loyalty => loyalty.membershipId === this.profile.membershipid,
            );
            this.points = curLoyalty?.amount
              ? parseInt(curLoyalty.amount, 10).toLocaleString()
              : null;
          } else if (!tbProfileIsLoading) {
            this.points = this.profile.points
              ? parseInt(this.profile.points, 10).toLocaleString()
              : null;
          } else {
            this.points = null;
          }
        }
      });
  }
  public ngOnDestroy(): void {
    this._isDestroyed$.next();
    this._isDestroyed$.complete();
  }

  public handleSignOut(): void {
    this._authFacade.redirectToLogoutPageOktaFlow();
  }
}
