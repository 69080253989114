import { Injectable } from '@angular/core';

import { removeErrorOfAction } from '../shared/action.utils';
import { StoreService } from '../shared/store.service';
import {
  DeleteProfilePicture,
  GetFutureReservationStatus,
  GetMemberData,
  GetMemberPayment,
  GetMemberPreferences,
  GetProfilePicture,
  PreferenceCenterActions,
  SaveMemberData,
  SaveMemberPreferences,
  SavePaymentsInfo,
  SaveProfilePicture,
  StoreFutureReservationStatus,
  StoreMemberData,
  StoreMemberPayment,
  StoreMemberPreferences,
  StoreProfilePicture,
} from './preference-center.actions';
import * as PreferenceCenterSelectors from './preference-center.selectors';
import {
  CreditCardRetrievalRequestState,
  CreditCardRetrievalResponseState,
  PersonalInfoState,
  TravelPreferencesState,
} from './types';

@Injectable({ providedIn: 'root' })
export class PreferenceCenterFacade {
  personalInfo = this.storeService.getState(
    PreferenceCenterSelectors.personalInfo,
  );
  profilePicture = this.storeService.getState(
    PreferenceCenterSelectors.profilePicture,
  );
  preferencesApiError = this.storeService.getState(
    PreferenceCenterSelectors.prefererencesApiError,
  );
  profilePictureApiError = this.storeService.getState(
    PreferenceCenterSelectors.profilePictureApiError,
  );
  travelPreferences = this.storeService.getState(
    PreferenceCenterSelectors.travelPreferences,
  );
  travelPrefererencesApiError = this.storeService.getState(
    PreferenceCenterSelectors.travelPrefererencesApiError,
  );
  prefererencesUpdateApiError = this.storeService.getState(
    PreferenceCenterSelectors.prefererencesUpdateApiError,
  );
  memberPrefererencesUpdateApiError = this.storeService.getState(
    PreferenceCenterSelectors.memberPrefererencesUpdateApiError,
  );
  futureReservationStatus = this.storeService.getState(
    PreferenceCenterSelectors.futureReservationStatus,
  );
  paymentInfo = this.storeService.getState(
    PreferenceCenterSelectors.paymentInfo,
  );
  travelBankBalance = this.storeService.getState(
    PreferenceCenterSelectors.travelBankBalance,
  );
  hasTravelCreditHistory = this.storeService.getState(
    PreferenceCenterSelectors.hasTravelCreditHistory,
  );
  paymentInfoApiError = this.storeService.getState(
    PreferenceCenterSelectors.paymentInfoApiError,
  );
  paymentUpdateApiError = this.storeService.getState(
    PreferenceCenterSelectors.paymentUpdateApiError,
  );
  profilePictureUpdateApiError = this.storeService.getState(
    PreferenceCenterSelectors.profilePictureUpdateApiError,
  );
  profilePictureDeleteApiError = this.storeService.getState(
    PreferenceCenterSelectors.profilePictureDeleteApiError,
  );

  constructor(private storeService: StoreService) {}

  getMemberData() {
    this.storeService.dispatchAction(new GetMemberData());
  }

  storeMemberData(info: PersonalInfoState) {
    return new StoreMemberData(info);
  }

  getProfilePicture() {
    this.storeService.dispatchAction(new GetProfilePicture());
  }

  storeProfilePicture(profilePicture: Blob) {
    return new StoreProfilePicture(profilePicture);
  }

  getMemberPreferencesData() {
    this.storeService.dispatchAction(new GetMemberPreferences());
  }

  storeMemberPreferencesData(info: TravelPreferencesState) {
    return new StoreMemberPreferences(info);
  }

  saveMemberData(attributeInfo: PersonalInfoState) {
    this.storeService.dispatchAction(new SaveMemberData(attributeInfo));
  }

  saveMemberPreferences(attributeInfo: TravelPreferencesState) {
    this.storeService.dispatchAction(new SaveMemberPreferences(attributeInfo));
  }

  getFutureReservationStatus() {
    this.storeService.dispatchAction(new GetFutureReservationStatus());
  }

  storeFutureReservationStatus(futureReservationStatus: Boolean) {
    return new StoreFutureReservationStatus(futureReservationStatus);
  }

  resetMemberDataErrorState() {
    this.storeService.dispatchAction(
      removeErrorOfAction(
        PreferenceCenterActions.MEMBER_DATA_ERROR_STATE_RESET,
        PreferenceCenterActions.UPDATE_STORE_MEMBER_DATA,
      ),
    );
  }

  resetMemberPreferencesErrorState() {
    this.storeService.dispatchAction(
      removeErrorOfAction(
        PreferenceCenterActions.MEMBER_PREFERENCES_ERROR_STATE_RESET,
        PreferenceCenterActions.UPDATE_STORE_MEMBER_PREFERENCES,
      ),
    );
  }

  getMemberPayment(info: CreditCardRetrievalRequestState) {
    this.storeService.dispatchAction(new GetMemberPayment(info));
  }

  storeMemberPayment(info: CreditCardRetrievalResponseState) {
    return new StoreMemberPayment(info);
  }

  savePaymentsInfo(cardInfo: Object) {
    this.storeService.dispatchAction(new SavePaymentsInfo(cardInfo));
  }

  resetPaymentInfoErrorState() {
    this.storeService.dispatchAction(
      removeErrorOfAction(
        PreferenceCenterActions.PAYMENT_INFO_ERROR_STATE_RESET,
        PreferenceCenterActions.UPDATE_STORE_PAYMENTS_INFO,
      ),
    );
  }

  resetPaymentInfoStoreErrorState() {
    this.storeService.dispatchAction(
      removeErrorOfAction(
        PreferenceCenterActions.PAYMENT_INFO_STORE_ERROR_STATE_RESET,
        PreferenceCenterActions.STORE_MEMBER_PAYMENT,
      ),
    );
  }

  saveProfilePicture(profilePicture: any) {
    this.storeService.dispatchAction(new SaveProfilePicture(profilePicture));
  }
  deleteProfilePicture() {
    this.storeService.dispatchAction(new DeleteProfilePicture());
  }

  resetProfilePictureErrorState() {
    this.storeService.dispatchAction(
      removeErrorOfAction(
        PreferenceCenterActions.PROFILE_PICTURE_ERROR_STATE_RESET,
        PreferenceCenterActions.UPDATE_STORE_PROFILE_PICTURE,
      ),
    );
  }
  resetDeleteProfilePictureErrorState() {
    this.storeService.dispatchAction(
      removeErrorOfAction(
        PreferenceCenterActions.DELETE_STORE_PROFILE_PICTURE_ERROR_STATE_RESET,
        PreferenceCenterActions.UPDATE_DELETE_STORE_PROFILE_PICTURE,
      ),
    );
  }
}
